<template>
  <div>
      <World />
  </div>
</template>

<script>
import World from "@/components/core/World.vue";

export default {
    components:{
        World
    }
}
</script>

<style>

</style>